<template>
  <b-card v-if="postData">
    <b-row>
      <!-- User Info: Left col -->
      <b-col
          cols="12"
          xl="4"
          class="d-flex justify-content-between"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start align-items-center">
          <b-avatar
              :src="pageData ? pageData.file.thumbnail : 'https://via.placeholder.com'"
              size="114px"
              rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ pageData.title }}
              </h4>

              <!--              <span class="card-text">{{ pageData.description }}</span>-->
            </div>
            <div class="d-flex flex-wrap">
              <!--              <b-button-->
              <!--                :to="{ name: 'apps-post-edit', params: { id: pageData.id } }"-->
              <!--                variant="danger"-->
              <!--              >-->
              <!--                Edit-->
              <!--              </b-button>-->
              <b-button
                  v-if="pageData.status !== 'approved'"
                  variant="success"
                  class="ml-1"
                  @click="changeStatus(pageData.id , {status: 'approved'})"
              >
                Approve it
              </b-button>
              <b-button
                  v-if="pageData.status !== 'rejected'"
                  variant="danger"
                  class="ml-1"
                  @click="changeStatus(pageData.id , {status: 'rejected'})"
              >
                Reject it
              </b-button>
            </div>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col>
        <div class="d-flex justify-content-end align-items-center h-100">
          <!--          <video-->
          <!--              ref="videoPlayer"-->
          <!--              class="video-js"-->
          <!--              width="320"-->
          <!--              height="240"-->
          <!--              controls-->
          <!--              :poster="pageData.thumbnail"-->
          <!--          >-->
          <!--            <source-->
          <!--                :src="pageData.file.url"-->
          <!--                type="application/x-mpegURL"-->
          <!--            >-->
          <!--            Your browser does not support the video tag.-->
          <!--          </video>-->
          <video-player
              :options="videoOptions">

          </video-player>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol,
} from 'bootstrap-vue'
import router from '@/router'
import videojs from 'video.js'
import usePostList from './usePostList'

// repository
import PostRepository from '@/abstraction/repository/postRepository'
import VideoPlayer from "@/views/components/video/VideoPlayer";

const postRepository = new PostRepository()

export default {
  components: {
    VideoPlayer,
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
  },
  props: {
    postData: {
      // type: Object,
      required: true,
    },
    options: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      pageData: this.postData,
      videoOptions: {
        autoplay: true,
        controls: true,
        height: 250,
        width: 350,
        sources: [
          {
            src:
            this.postData.file.url,
            type: "application/x-mpegURL"
          }
        ],
        poster: this.postData.thumbnail,
      },
    };
  },
  methods: {
    async deletePost(postId) {
      await postRepository.delete(postId)
      router.push({name: 'apps-post-list'})
    },
    async changeStatus(postId , data){
      this.pageData = await postRepository.verify(postId , data)
      // this.$emit('reloadData')
    }
  },
}
</script>

<style></style>
